import { SET_CRITICAL_CSS, SET_CRITICAL_CSS_IN_CACHE } from './constants';

/**
 * SET_CRITICAL_CSS action definition
 * Implements PLAT-1288: Allow page to control creation of critical css
 * @param  {Boolean|Object} config Page critical css config
 *   - If the parameter is a boolean, then its value will be stored in redux state as a flag to indicate
 *     whether the result of rendering the page should be used to generate critical css. True (boolean) is a default value
 *     - If the critical css flag is false in redux then the critical css feature is disabled for this request - there will be no
 *       attempt to get critical css or genereate critical css from this request.
 *       Please be advised that this value is rarely justified in pracitce, consult platform team if in doubt whether it is suited for your use case.
 *   - The parameter can be a flat object of key value pairs, no nested objects, no more than 8 keys. Object prop/values will
 *     be stringified as key/val pairs and used as a custom critical css cache key
 *   - @param {Number} config.criticalCssHeight - (ex: 1280) you may use this option to specify height of critical css. Value should be
 *     an integer and will stand for pixels. This will essentially override the default height for critical css of 896px
 *     for mobile and 1000px for desktop, which are meant to cover a single fold for majority of screens used by edmunds users.
 *     This is an experimental feature, please use it with extreme caution because higher numbers, while may or may not
 *     address some CLS issues related to back button browsing, WILL inevitably increase download size thus FCP, LCP.
 *
 *  If there is a critical css cache key in redux then the page renderer will append it to the critical css service url
 *  as a cacheKey parameter for both requesting and generating critical css
 *
 * @return {Object} SET_CRITICAL_CSS action definition
 */
export function setCriticalCss(config) {
  return {
    type: SET_CRITICAL_CSS,
    config,
  };
}

/**
 * SET_CRITICAL_CSS_IN_CACHE action definition
 * Implements PLAT-3931: Providing criticalCssLoadedFromLocalCache in redux for debugging purpose
 * @param  {Boolean} payload is critical css coming from local cache
 * @return {Object} SET_CRITICAL_CSS_IN_CACHE action definition
 */
export function setCriticalCssInCache(payload) {
  return {
    type: SET_CRITICAL_CSS_IN_CACHE,
    payload,
  };
}
